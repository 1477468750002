<template>
  <div class="row">
    <div class="col-12">
        <div class="card card-body">
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" style="width: 50px; height; 50px;" alt="">
            <div class="col-12 table-responsive">
                <div class="form-group col-12 col-lg-6 g">
                  <label for="">اختيار وكيل معين</label>
                  <select class="form-control" name="" id="" v-model="selected_cash">
                    <option :value="false">-- اختر --</option>
                    <option v-for="cash in cashsarr" :value="cash._id" :key="cash._id">{{ cash.name }} ({{ countt(cash._id) }})</option>
                  </select>
                </div>
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                           <b-form-checkbox
                            @change="!all ? selected = [] : selected = orders.map(function(x){
                                if(x.reseller_id == selected_cash){return x._id}else{return false;}
                                }).filter(function(x){return x})"
                            v-model="all"
                            :value="true"
                            >
                           تحديد الكل
                        </b-form-checkbox>
                        </th>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهاتف
                        </th>
                        <th>
                            الوكيل
                        </th>
                        <th>
                            الفندق
                        </th>
                        <th>
                            تاريخ الدخول
                        </th>
                        <th>
                            عدد الايام
                        </th>
                        <th>
                            تاريخ التحصيل
                        </th>
                        <th>
                            الاجمالي
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            عمولة الوكيل
                        </th>
                        <th>
                            مديونية الوكيل
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="order in orders">
                        <tr :key="order._id" v-if="order.reseller_id == selected_cash">
                            <td>
                                <b-form-checkbox
                                    v-model="selected"
                                    :value="order._id"
                                    >
                                    {{ order.id }}
                                </b-form-checkbox>
                            </td>
                            <td>
                                {{ order.name }}
                            </td>
                            <td>
                                {{ order.phone }}
                            </td>
                            <td>
                                {{ order.reseller.name }}
                            </td>
                            <td>
                                {{ order.hotel.title }}
                            </td>
                            <td>
                                {{ order.days[0] }}
                            </td>
                            <td>
                                {{ order.days.length - 1 }}
                            </td>
                            <td>
                                {{ order.cash_done_date }}
                            </td>
                            <td>
                                {{ order.total_reseller + order.extra }} ريال
                            </td>
                            <th>
                                <span class="btn btn-sm btn-warning" style="border-radius: 0px;" v-if="order.status == 0">في الانتظار</span>
                                <span class="btn btn-sm btn-success" style="border-radius: 0px;" v-if="order.status == 1">تم التأكيد</span>
                                <span class="btn btn-sm btn-danger" style="border-radius: 0px;" v-if="order.status == 2">في انتظار الالغاء</span>
                                <span class="btn btn-sm btn-secondary" style="border-radius: 0px;" v-if="order.status == 3">ملغي</span>
                            </th>
                            <td>
                                {{ order.extra }} ريال
                            </td>
                            <td>
                                {{ order.reseller_dept }} ريال
                            </td>
                        </tr></template>
                    </tbody>
                </table>
            </div>
            <div class="col-12 text-center g" v-if="selected.length">
                <button class="btn btn-primary" @click="getCashs()" v-b-modal.modal-1>
                    <i class="fa fa-check"></i>
                    تمت محاسبة الوكيل
                </button>
            </div>
        </div>
    </div>
    <b-modal id="modal-1" size="lg" title="تعيين الطلبات انها تمت المحاسبة عليها من الوكيل" hide-footer>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered">
                <thead>
                    <th>
                        الوكيل
                    </th>
                    <th>
                        اجمالي الطلبات
                    </th>
                    <th>
                       عمولة الوكيل
                    </th>
                    <th>
                       مديونيات الوكيل
                    </th>
                </thead>
                <tbody>
                    <tr v-for="cash in cashs" :key="cash.name">
                        <td>{{ cash.name }}</td>
                        <td>{{ cash.total }}</td>
                        <td>{{ cash.extra }}</td>
                        <td>{{ cash.dipt }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="alert alert-danger text-center g">
            هذه الخطوة لا يمكن التراجع عنها
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="done()">
                <i class="fa fa-check"></i>
                تمت محاسبة الوكيل
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    components: {
        BModal,BFormCheckbox,
    },
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            orders: [],
            loading: false,
            selected: [],
            all: false,
            cashs: [],
            selected_cash: false,
            cashsarr: []
        }
    },
    beforeDestroy(){
        clearInterval(this.cc)
    },
    created(){
        var g = this;
        this.getOrders();
    },
    methods: {
        countt(id){
            var g = this;
            var t = 0;
            g.orders.forEach(function(a){
                if(a.reseller_id == id){
                    t++
                }
            })
            return t;
        },
        getOrders(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/orders/reseller-orders', {
                jwt: this.jwt
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.orders = r.response
                }
                $.post(api + '/admin/resellers/all', {
                    jwt: g.jwt
                }).then(function(r){
                    g.loading = false;
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.cashsarr = r.response
                    }
                }).fail(function(){
                    g.loading = false;
                    alert("حدث مشكلة في الاتصال")
                })
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        },
        getCashs(){
            var g = this;
            var arr = {};
            g.orders.forEach(function(a){
                if(g.selected.includes(a._id)){
                    if(a){
                        if(!arr[a.reseller_id]){
                            arr[a.reseller_id] = {
                                total: 0,
                                extra: 0,
                                dipt: 0
                            }
                        }
                        arr[a.reseller_id].name = a.reseller.name;
                        arr[a.reseller_id].total = arr[a.reseller_id].total + a.total_reseller + a.extra;
                        arr[a.reseller_id].extra = arr[a.reseller_id].extra + a.extra;
                        arr[a.reseller_id].dipt = arr[a.reseller_id].dipt + a.reseller_dept;
                    }
                }
            })
            var arr2 = [];
            for (const [key, value] of Object.entries(arr)) {
                arr2.push(value)
            }
            g.cashs = arr2
        },
        done(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/orders/reseller-done', {
                jwt: this.jwt,
                ids: this.selected
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    $("#modal-1___BV_modal_header_ > button").click()
                    g.getOrders()
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        }
    }
}
</script>

<style>

</style>